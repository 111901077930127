import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p>{`For time:`}</p>
    <p>{`10-Bar Muscle Ups`}</p>
    <p>{`20-Step Ups/Leg (40/25’s, 24/20″)`}</p>
    <p>{`30-Burpees`}</p>
    <p>{`40-Goblet Squats (53/35)`}</p>
    <p>{`50-Calorie Row`}</p>
    <p>{`60-OH Walking Lunge Steps (45/25)`}</p>
    <p>{`70-KBS’s (53/35)`}</p>
    <p>{`80-Situps`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      